@import "../fonts/Inter\ Web/inter.css";

$site-font-family: "Inter";
$site-font-color: #212121;

body {
  margin: 0;
  font-family: $site-font-family;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #d9d9d9;
  color: $site-font-color;
  font-size: 18px;
}

// span {
//   font-family: $site-font-family;
//   color: $site-font-color;
// }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

$header-font-weight: 300;
h1,
h2,
h3,
h4,
h5 {
  font-weight: $header-font-weight;
  // color: #a1cbe7;
  text-align: center;
}
table {
  margin-left: auto;
  margin-right: auto;
}
td {
  padding: 10px;
}
.normal-font-color {
  color: $site-font-color;
}
